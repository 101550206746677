/* messages.css */

.conversation-messages {
  display: flex;
  flex-direction: column;
  padding: 10px; /* Add padding for aesthetics */
  overflow-y: auto; /* Scrollable area for overflow */
}

.messages-container {
  display: flex;
  height: 100vh;
  background-color: #f3f4f6; /* Light grey background */
  margin-top: 50px;
  width: 100%;
}

.sidebar {
  width: 20%; /* Sidebar takes up 1/4 of the width */

  background-color: #e7ecef; /* White background for sidebar */
  overflow-y: auto; /* Allows scrolling of sidebar */
  border-right: 3px solid #292929; /* Light grey border */
}

.main-chat {
  width: 80%; /* Main chat takes up 50% of the width */
  background-color: #e7ecef; /* Slightly darker grey background for contrast */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allows scrolling of chat area */
}

/* Base styles for bubbles */
.message-bubble {
  max-width: 75%;
  padding: 8px 16px;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  word-break: break-word; /* To prevent overflow */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .message-bubble {
    max-width: 85%; /* Allow the bubble to be a bit wider on small screens */
  }

  /* Adjust sidebar and main chat area for mobile view */
  .sidebar {
    width: 35%; /* Increase the width of the sidebar on small screens */
  }

  .main-chat {
    flex-grow: 1; /* Ensure the chat area fills the remaining space */
  }
}

/* Very small screens, typically mobile devices */
@media (max-width: 480px) {
  .sidebar {
    /* Hide the sidebar by default on mobile devices */
    width: 30%; /* Full width when displayed */
  }

  .main-chat {
    width: 70%; /* Main chat takes full width */
  }
}

/* Styles for sender and receiver bubbles */
.message-bubble-sender {
  background-color: #292929; /* Pink color */
  color: white;
  align-self: flex-end; /* Align to the right */
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; /* Rounded corners */
  margin-left: auto; /* Push to the right */
  max-width: 75%; /* Maximum width */
}

.message-bubble-receiver {
  background-color: #f0f0f0; /* Light grey color */
  color: #333;
  align-self: flex-start; /* Align to the left */
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px; /* Rounded corners */
  max-width: 75%; /* Maximum width */
}

/* Message input container */
.message-input-container {
  display: flex;
  width: 100%;
  justify-content: flex-end; /* Aligns children to the right */
  padding: 8px; /* Adds some padding around the container */
  gap: 8px; /* Adds space between input and button */
}

/* Message input field */
.message-input-field {
  flex-grow: 1; /* Input field takes up available space */
  padding: 8px 16px; /* Padding inside the input field */
  border: 1px solid #ddd; /* Border around the input field */
  border-radius: 4px 0 0 4px; /* Rounded corners on the left side */
  outline: none; /* Removes the default focus outline */
}

/* Send button */
.send-button {
  padding: 8px 16px; /* Padding inside the button */
  background-color: #292929; /* Blue background */
  color: white; /* White text */
  border: none; /* No border */
  border-radius: 0 4px 4px 0; /* Rounded corners on the right side */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

/* Adjustments for hover state */
.send-button:hover {
  background-color: #292929; /* Darker blue on hover */
}
