/* Button Styling */
.button {
  background-color: #fb6f92;
  color: #202020;
  padding: 10px 10px;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  transition: background-image 0.3s, box-shadow 0.3s;
}

/* Add this to your CSS file */
.dialogTextRed {
  color: red;
}

.infoTextRed {
  color: rgb(237, 62, 18); /* existing style */
  font-size: 0.8em; /* Smaller font size */
}

.button:hover {
  background-color: #202020;
  color: #fb6f92;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Add some styling to the selected item details */
.selected-item-details {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-item-details h3 {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.selected-item-details p {
  font-size: 14px;
  margin: 5px 0;
  color: #555;
}

.selected-item-details button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selected-item-details button:hover {
  background-color: #c0392b;
}

/* Add some styling to the "No Item" message */
.no-item-message-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.no-item-message-button:hover {
  background-color: #2980b9;
}

/* CSS for the switch */
.switch-label {
  color: #3498db; /* Change the color as needed */
}

/* Style for the switch track */
.switch-track {
  background-color: #ccc; /* Change the background color as needed */
}

/* Style for the switch thumb */
.switch-thumb {
  background-color: #3498db; /* Change the background color as needed */
}

/* Add any additional styles you need for your components */
