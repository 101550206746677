/* Legend container */
.legend {
  background-color: #fff;
  /* border: 1px solid #ccc; */
  padding: 10px;
  border-radius: 5px;
  display: grid;

  grid-gap: 5px; /* Adjust the gap as needed */
}

/* Legend item */
.legend-item {
  display: flex;
  align-items: center;
}

/* Legend text */
.legend-text {
  font-size: 12px; /* Adjust the font size as needed */
}

/* Legend icon */
.legend-icon {
  width: 24px;
  height: 32px;
  /* margin-right: 5px;
  border-radius: 50%; */
}

/* Add more icon styles for other categories */
