/* MyItemsTableComponent.css */
.radar-effect {
  position: relative;
  display: inline-block;
}

.radar-effect::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(
    37,
    214,
    37,
    1
  ); /* Green background with 50% opacity */
  border: 1px solid rgba(4, 105, 4, 0.496); /* Green border with 50% opacity */

  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 50%;

  animation: radarScan 1.5s infinite linear;
}

@keyframes radarScan {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.my-items-table-container {
  overflow-x: auto;
  white-space: nowrap;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container .MuiLink-root,
.icon-container .MuiIconButton-root {
  /* Override specific styles for MUI Link and IconButton */
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
