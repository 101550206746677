/* Modal Styling */
.modal-card {
  background-color: #ffffff;
  margin: 5% auto;
  padding: 20px;
  border: none;
  width: 80%;
  max-width: 450px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  animation: animatetop 0.4s;
}

.modal.is-active {
  display: block;
}

/* Input and Textarea Enhancement */
.input,
.textarea {
  border: 1px solid #e0e0e0;
  box-shadow: none;
  transition: border 0.3s, box-shadow 0.3s;
  padding: 8px 10px;
}

.input:focus,
.textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Button Styling */
.button {
  background-color: #fb6f92;
  color: #202020;
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  transition: background-image 0.3s, box-shadow 0.3s;
}

.button:hover {
  background-color: #202020;
  color: #fb6f92;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Select Elements */
.select select {
  border: 1px solid #e0e0e0;
  box-shadow: none;
  transition: border 0.3s;
  padding: 8px 10px;
}

.select select:hover {
  border-color: #007bff;
}

/* General Aesthetic Adjustments */
.modal-card-head,
.modal-card-foot {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
}

.delete {
  color: #6c757d;
}

.delete:hover {
  color: #dc3545;
}

/* Keyframes for modal animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
